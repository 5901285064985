<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <knowledgeIndex v-show="!$store.state.complainManageTag"></knowledgeIndex>
      <!--搜索条件区域-->
      <div v-show="$store.state.complainManageTag">
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
          class="bgFFF"
          style="padding-left: 10px"
        >
          <el-tab-pane label="未办结" name="1" style="padding-left: 10px"></el-tab-pane>
          <el-tab-pane label="全部" name="2"></el-tab-pane>
        </el-tabs>
        <div class="searchWrapper" @keydown.enter.prevent="searchData">
          <el-form
            :inline="true"
            label-position="right"
            :model="formInline"
            class="demo-form-inline"
          >
            <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
            <div class="col_box">
              <div class="col_left">
                <el-form-item :label="$t('searchModule.Work_Order_Number')">
                  <el-input
                    v-model="formInline.orderNo"
                    maxlength="30"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
                <el-form-item :label="$t('searchModule.source')">
                  <el-select
                    v-model="formInline.dataSource"
                    placeholder="请选择"
                    @change="dataSourceChange"
                  >
                    <el-option label="请选择" value></el-option>
                    <el-option
                      v-for="item in customerDataSourceList"
                      :key="item.value"
                      :label="item.desc"
                      :value="item.code"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('searchModule.Work_order_type')">
                  <el-select v-model="formInline.orderType" placeholder="请选择">
                    <el-option label="请选择" value></el-option>
                    <el-option
                      v-for="item in orderTypeList"
                      :key="item.value"
                      :label="item.desc"
                      :value="item.code"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('searchModule.contacts')">
                  <el-input
                    v-model="formInline.name"
                    maxlength="20"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                  <el-input
                    v-model="formInline.phone"
                    maxlength="11"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
                <el-form-item label="创建人">
                  <el-input
                    v-model="formInline.operationName"
                    maxlength="20"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
                <el-form-item :label="$t('searchModule.state')">
                  <el-select v-model="formInline.complaintStatus" placeholder="请选择">
                    <el-option label="请选择" value></el-option>
                    <el-option label="未处理" value="1"></el-option>
                    <el-option label="跟进中" value="2"></el-option>
                    <el-option label="已办结" value="3" v-show="activeName === '2'"></el-option>
                  </el-select>
                </el-form-item>
                <div>
                  <el-form-item class="timeBox">
                    <el-select v-model="formInline.timeType" placeholder="请选择">
                      <el-option
                        label="派单时间"
                        value="1"
                        v-show="
                          formInline.dataSource == '' ||
                          formInline.dataSource == 1 ||
                          formInline.dataSource == 2
                        "
                      ></el-option>
                      <el-option
                        label="截止时间"
                        value="2"
                        v-show="
                          formInline.dataSource == '' ||
                          formInline.dataSource == 1 ||
                          formInline.dataSource == 2
                        "
                      ></el-option>
                      <el-option :label="$t('searchModule.Creation_time')" value="3"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="timeDate">
                    <el-date-picker
                      v-model="sendDate"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :clearable="false"
                      @change="sendDateChange"
                    ></el-date-picker>
                  </el-form-item>
                </div>
              </div>
              <div class="col_right">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="
                    page = 1;
                    searchData();
                  "
                  :loading="loading"
                  v-if="$route.meta.authority.button.query"
                  >{{ $t('button.search') }}</el-button
                >
              </div>
            </div>
            <div class="col_box_boder"></div>
            <!-- 如果没有下面按钮就不需要 -->
            <div class="col_box h44">
              <div class="col_left">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  @click="$router.push('addComplain')"
                  v-if="$route.meta.authority.button.add"
                  :loading="loading"
                  >创建</el-button
                >
                <el-button
                  type="info"
                  plain
                  icon="el-icon-upload"
                  @click="openMuli()"
                  :loading="loading"
                  v-if="$route.meta.authority.button.import"
                  >{{ $t('button.import') }}</el-button
                >
                <el-button
                  @click="addSeting"
                  v-if="$route.meta.authority.button.setting"
                  type="info"
                  icon="el-icon-setting"
                  >设置
                </el-button>
              </div>
              <div class="col_right mbd4">
                <el-button
                  type="info"
                  icon="el-icon-upload2"
                  @click="exportExcelReport()"
                  :loading="loading"
                  v-if="$route.meta.authority.button.export"
                  >{{ $t('button.export') }}</el-button
                >
              </div>
            </div>
          </el-form>
        </div>
        <!--列表区域-->
        <div class="tableWrapper bgFFF padding10">
          <div class="statistics">
            <div class="child">
              <span class="title">待处理：</span>
              <span class="txt">{{ statistics.undisposnum }}</span>
            </div>
            <div class="child">
              <span class="title">跟进中：</span>
              <span class="txt">{{ statistics.workingnum }}</span>
            </div>
            <div class="child">
              <span class="title">已完结：</span>
              <span class="txt" :style="{ color: statistics.finishnum > 0 ? 'red' : '' }">{{
                statistics.finishnum
              }}</span>
            </div>
          </div>
          <el-table
            header-cell-class-name="header-call-style"
            border
            v-loading="loading"
            :data="tableData"
            style="width: 100%"
            :row-style="rowStyle"
          >
            <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
            <el-table-column
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              v-for="item in tableCols"
              :key="item.prop"
              :formatter="item.formatter"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="操作"
              align="center"
              width="90"
              v-if="$route.meta.authority.button.detail || $route.meta.authority.button.deal"
            >
              <template slot-scope="scope">
                <el-dropdown @command="handleCommand($event, scope.row)">
                  <el-button type="text" size="small" style="padding: 0"
                    >操作<i class="el-icon-arrow-down"
                  /></el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="a" v-if="$route.meta.authority.button.detail"
                      >查询详情</el-dropdown-item
                    >
                    <el-dropdown-item
                      command="b"
                      v-show="
                        scope.row.complaintStatus !== '已办结' && $route.meta.authority.button.deal
                      "
                      >处理</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="pagerWrapper">
            <div class="block">
              <el-pagination
                v-if="total != 0"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="total"
              ></el-pagination>
            </div>
          </div>
        </div>
        <!-- 导出组件 -->
        <upload-file
          ref="upload"
          :uploadData="uploadData"
          @success="searchData"
          :uploadUrl="uploadUrl"
          :modelUrl="modelUrl"
        ></upload-file>
      </div>
    </div>
  </div>
</template>
<script>
// import autoComplete from "@/mycomponents/myautocomplete";
import { dateFormat, exportExcelNew } from "@/common/js/public";
import uploadFile from "@/components/uploadFile/";
import knowledgeIndex from "./knowledgeIndex.vue";
let today = dateFormat(new Date(), "yyyy-MM-dd");
let yesterday = dateFormat(new Date(new Date().getTime() - 86400000 * 31), "yyyy-MM-dd");
export default {
  name: "checkUpCustomer",
  components: {
    // autoComplete,
    uploadFile,
    knowledgeIndex,
  },
  data() {
    return {
      activeName: "1",
      uploadUrl: "/acb/2.0/customerComplaint/import",
      modelUrl: "/acb/2.0/customerComplaint/downloadExcelTemplate",
      uploadData: {
        parkId: this.$route.query.parkId,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      page: 1,
      pageSize: 15,
      total: 0,
      tableCols: [
        {
          prop: "orderNo",
          label: "工单编号",
          width: "",
        },
        {
          prop: "dataSource",
          label: "来源",
          width: "",
        },
        {
          prop: "name",
          label: "联系人",
          width: "",
        },
        {
          prop: "phone",
          label: "联系电话",
          width: "",
        },
        {
          prop: "orderType",
          label: "工单类型",
          width: "",
        },
        {
          prop: "platformTime",
          label: "派单时间",
          width: "",
        },
        {
          prop: "cutoffTime",
          label: "截止时间",
          width: "",
        },
        {
          prop: "operationName",
          label: "创建人",
          width: "",
        },
        {
          prop: "complaintStatus",
          label: this.$t("list.state"),
          width: "",
        },
      ],
      complaintStatus: "1,2",
      sendDate: [yesterday, today],
      orderTypeList: [],
      complaintStatusList: [],
      customerDataSourceList: [],
      loading: false,
      tableData: [],
      statistics: [],
      formInline: {
        timeType: "3",
        dataSource: "",
        orderNo: "",
        orderType: "",
        name: "",
        phone: "",
        operationName: "",
        complaintStatus: "",
        startTime: yesterday,
        endTime: today,
      },
    };
  },
  computed: {
    parkName() {
      return this.formInline.parkName;
    },
  },
  methods: {
    addSeting() {
      if (this.$store.state.complainManageTag == true) {
        this.$store.commit("SET_ComplainManageTag", false);
      }
    },
    getStatistics() {
      this.$axios
        .get("/acb/2.0/customerComplaint/getComplaintCount", {
          data: {
            ...this.formInline,
            complaintStatus: this.formInline.complaintStatus
              ? this.formInline.complaintStatus
              : this.complaintStatus,
            entryOrExit: this.formInline.entryOrExit,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.statistics = res.value || {};
        });
    },
    dataSourceChange() {
      if (this.formInline.dataSource === 3 || this.formInline.dataSource === 4) {
        this.formInline.timeType = "3";
      }
    },
    handleClick() {
      this.tableData = [];
      this.total = 0;
      this.complaintStatus = this.activeName === "1" ? "1,2" : "";
      this.formInline.complaintStatus = "";
      this.searchData();
    },
    getAddOption() {
      this.$axios.get("/acb/2.0/customerComplaint/getAddOption").then((res) => {
        this.complaintStatusList = res.value.customerStatus;
        this.orderTypeList = res.value.customerOrderType;
        this.customerDataSourceList = res.value.customerDataSource;
      });
    },
    sendDateChange() {
      this.formInline.startTime = dateFormat(this.sendDate[0], "yyyy-MM-dd");
      this.formInline.endTime = dateFormat(this.sendDate[1], "yyyy-MM-dd");
    },
    exportExcelReport() {
      if (this.formInline.startTime && this.formInline.endTime) {
        exportExcelNew("/acb/2.0/customerComplaint/export", {
          ...this.formInline,
          complaintStatus: this.formInline.complaintStatus
            ? this.formInline.complaintStatus
            : this.complaintStatus,
        });
      } else {
        this.$alert("时间日期有误");
      }
    },
    openMuli() {
      this.$refs.upload.clearFiles();
      this.$refs.upload.open();
    },
    handleCommand(cmd, data) {
      if (cmd === "a") {
        this.$router.push({
          path: "/customerDetails",
          query: {
            complaintId: data.complaintId,
          },
        });
      }
      if (cmd === "b") {
        this.$router.push({
          path: "/disposeComplain",
          query: {
            complaintId: data.complaintId,
          },
        });
      }
    },
    rowStyle(row) {
      // console.log(row.row.remindTime < dateFormat(new Date(new Date().getTime() + 86400000), 'yyyy-MM-dd'))
      if (row.row.complaintStatus != "已办结" && row.row.dateStatus == 1) {
        return { color: "red" };
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    searchData() {
      // 重新获取统计数据
      this.getStatistics();
      this.loading = true;
      this.$axios
        .get("/acb/2.0/customerComplaint/list", {
          data: {
            ...this.formInline,
            complaintStatus: this.formInline.complaintStatus
              ? this.formInline.complaintStatus
              : this.complaintStatus,
            entryOrExit: this.formInline.entryOrExit,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((res) => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getAddOption();
    // this.getStatistics();
    this.searchData();
  },
  activated() {},
};
</script>
<style lang="stylus" scoped>
.statistics {
  float: right;
  margin-right: 50px;
  margin-top: 10px;
  margin-bottom: 10px;

  .child {
    display: inline-block;

    .title, .txt, .pagerWrapper {
      text-align: right;
      margin-top: 28px;
      margin-right: 10px;
      font-size: 12px;
    }
  }
}
::v-deep .timeBox{
  margin-left:100px
  .el-form-item__content{
    width:160px
  }
}

::v-deep .timeDate{
  margin-left:-160px
  .el-form-item__content{
    width:280px
    .el-input__inner{
      width:288px
    }
  }
  }
</style>
