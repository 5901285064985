<template>
     <el-dialog
        title="批量导入"
        :visible.sync="dialogVisible"
        width="600px"
        >
        <div>1.请先获取批量添加设备的Excel模板将需要添加的信息填写后进行上传该Excel文件，
          上传非规范文件可能造成数据错误，<el-button type='text' @click='exportModel'>点击下载</el-button>模板文件。
        </div>
        <div>
            2.请选择上传文件，并上传。
        </div>
        <div style='margin-top:20px;'>
            <el-upload
              class="upload-demo"
              ref="upload"
              :headers = 'headers'
              :on-success='successCallback'
              :on-change='fileChange'
              :show-file-list='false'
              :data='uploadData'
              :on-error='fileError'
              :action="uploadUrl"
              :limit='1'
              :auto-upload="false">
              <el-input v-model="fileName" style='width:200px;float:left;margin-right:5px;'></el-input><el-button slot="trigger" @click='uploadClick' type="primary">浏览...</el-button>
            </el-upload>
        </div>
        <div slot="footer" class="dialog-footer" style='text-align:center'>
          <el-button type="primary" @click="submitUpload">上传</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
      </el-dialog>
</template>
<script>
import {exportExcelNew} from '@/common/js/public'
export default {
    data () {
        return {
            dialogVisible: false,
            fileName: '',
            headers: {
            'Session-Id': sessionStorage.token
            }
        }
    },
    props: ['uploadUrl', 'modelUrl', 'uploadData'],
    methods: {
        uploadClick () {
            this.$refs.upload.clearFiles();
        },
        fileChange (file) {
            if (file.name && !file.response) {
            this.fileName = file.name;
            this.picFlag = true;
            } else {
            this.$refs.upload.clearFiles();
            this.fileName = ''
            this.picFlag = false;
            }
        },
        fileError (err) {
          // console.log(err)
        },
        submitUpload () {
            if (this.picFlag) {
                this.$refs.upload.submit();
            } else {
                this.$alert('请选择需要上传的文件', this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
                });
            }
        },
        successCallback (res) {
            console.log("res-->", res);
            if (res.state == 0) {
                this.$alert('操作成功！', this.$t('pop_up.Tips'), {
                        confirmButtonText: this.$t('pop_up.Determine')
                        }).then(() => {
                            this.$emit('success', res);
                            this.dialogVisible = false;
                        })
            } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                        confirmButtonText: this.$t('pop_up.Determine')
                        })
            }
        },
        open () {
            this.dialogVisible = true;
        },
        clearFiles () {
            this.$refs.upload && this.$refs.upload.clearFiles();
            this.fileName = '';
        },
        exportModel () {
            exportExcelNew(this.modelUrl, this.uploadData);
        }
    }
}
</script>
