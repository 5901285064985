var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("knowledgeIndex", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$store.state.complainManageTag,
              expression: "!$store.state.complainManageTag",
            },
          ],
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.state.complainManageTag,
                expression: "$store.state.complainManageTag",
              },
            ],
          },
          [
            _c(
              "el-tabs",
              {
                staticClass: "bgFFF",
                staticStyle: { "padding-left": "10px" },
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c("el-tab-pane", {
                  staticStyle: { "padding-left": "10px" },
                  attrs: { label: "未办结", name: "1" },
                }),
                _c("el-tab-pane", { attrs: { label: "全部", name: "2" } }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "searchWrapper",
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    return _vm.searchData.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    staticClass: "demo-form-inline",
                    attrs: {
                      inline: true,
                      "label-position": "right",
                      model: _vm.formInline,
                    },
                  },
                  [
                    _c("div", { staticClass: "search_box_title" }, [
                      _vm._v(_vm._s(_vm.$t("searchModule.Query_Table"))),
                    ]),
                    _c("div", { staticClass: "col_box" }, [
                      _c(
                        "div",
                        { staticClass: "col_left" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("searchModule.Work_Order_Number"),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "30",
                                  placeholder: "请输入内容",
                                },
                                model: {
                                  value: _vm.formInline.orderNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formInline, "orderNo", $$v)
                                  },
                                  expression: "formInline.orderNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("searchModule.source") } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  on: { change: _vm.dataSourceChange },
                                  model: {
                                    value: _vm.formInline.dataSource,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "dataSource",
                                        $$v
                                      )
                                    },
                                    expression: "formInline.dataSource",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "请选择", value: "" },
                                  }),
                                  _vm._l(
                                    _vm.customerDataSourceList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.desc,
                                          value: item.code,
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("searchModule.Work_order_type"),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.formInline.orderType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formInline, "orderType", $$v)
                                    },
                                    expression: "formInline.orderType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "请选择", value: "" },
                                  }),
                                  _vm._l(_vm.orderTypeList, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.desc,
                                        value: item.code,
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: _vm.$t("searchModule.contacts") },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  placeholder: "请输入内容",
                                },
                                model: {
                                  value: _vm.formInline.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formInline, "name", $$v)
                                  },
                                  expression: "formInline.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系电话" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "11",
                                  placeholder: "请输入内容",
                                },
                                model: {
                                  value: _vm.formInline.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formInline, "phone", $$v)
                                  },
                                  expression: "formInline.phone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "创建人" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  placeholder: "请输入内容",
                                },
                                model: {
                                  value: _vm.formInline.operationName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formInline,
                                      "operationName",
                                      $$v
                                    )
                                  },
                                  expression: "formInline.operationName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("searchModule.state") } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.formInline.complaintStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "complaintStatus",
                                        $$v
                                      )
                                    },
                                    expression: "formInline.complaintStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "请选择", value: "" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "未处理", value: "1" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "跟进中", value: "2" },
                                  }),
                                  _c("el-option", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.activeName === "2",
                                        expression: "activeName === '2'",
                                      },
                                    ],
                                    attrs: { label: "已办结", value: "3" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                { staticClass: "timeBox" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.formInline.timeType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formInline,
                                            "timeType",
                                            $$v
                                          )
                                        },
                                        expression: "formInline.timeType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.formInline.dataSource == "" ||
                                              _vm.formInline.dataSource == 1 ||
                                              _vm.formInline.dataSource == 2,
                                            expression:
                                              "\n                        formInline.dataSource == '' ||\n                        formInline.dataSource == 1 ||\n                        formInline.dataSource == 2\n                      ",
                                          },
                                        ],
                                        attrs: {
                                          label: "派单时间",
                                          value: "1",
                                        },
                                      }),
                                      _c("el-option", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.formInline.dataSource == "" ||
                                              _vm.formInline.dataSource == 1 ||
                                              _vm.formInline.dataSource == 2,
                                            expression:
                                              "\n                        formInline.dataSource == '' ||\n                        formInline.dataSource == 1 ||\n                        formInline.dataSource == 2\n                      ",
                                          },
                                        ],
                                        attrs: {
                                          label: "截止时间",
                                          value: "2",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "searchModule.Creation_time"
                                          ),
                                          value: "3",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { staticClass: "timeDate" },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      clearable: false,
                                    },
                                    on: { change: _vm.sendDateChange },
                                    model: {
                                      value: _vm.sendDate,
                                      callback: function ($$v) {
                                        _vm.sendDate = $$v
                                      },
                                      expression: "sendDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col_right" },
                        [
                          _vm.$route.meta.authority.button.query
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-search",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.page = 1
                                      _vm.searchData()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("button.search")))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col_box_boder" }),
                    _c("div", { staticClass: "col_box h44" }, [
                      _c(
                        "div",
                        { staticClass: "col_left" },
                        [
                          _vm.$route.meta.authority.button.add
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-plus",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.push("addComplain")
                                    },
                                  },
                                },
                                [_vm._v("创建")]
                              )
                            : _vm._e(),
                          _vm.$route.meta.authority.button.import
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "info",
                                    plain: "",
                                    icon: "el-icon-upload",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openMuli()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("button.import")))]
                              )
                            : _vm._e(),
                          _vm.$route.meta.authority.button.setting
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "info",
                                    icon: "el-icon-setting",
                                  },
                                  on: { click: _vm.addSeting },
                                },
                                [_vm._v("设置 ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col_right mbd4" },
                        [
                          _vm.$route.meta.authority.button.export
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "info",
                                    icon: "el-icon-upload2",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.exportExcelReport()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("button.export")))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tableWrapper bgFFF padding10" },
              [
                _c("div", { staticClass: "statistics" }, [
                  _c("div", { staticClass: "child" }, [
                    _c("span", { staticClass: "title" }, [_vm._v("待处理：")]),
                    _c("span", { staticClass: "txt" }, [
                      _vm._v(_vm._s(_vm.statistics.undisposnum)),
                    ]),
                  ]),
                  _c("div", { staticClass: "child" }, [
                    _c("span", { staticClass: "title" }, [_vm._v("跟进中：")]),
                    _c("span", { staticClass: "txt" }, [
                      _vm._v(_vm._s(_vm.statistics.workingnum)),
                    ]),
                  ]),
                  _c("div", { staticClass: "child" }, [
                    _c("span", { staticClass: "title" }, [_vm._v("已完结：")]),
                    _c(
                      "span",
                      {
                        staticClass: "txt",
                        style: {
                          color: _vm.statistics.finishnum > 0 ? "red" : "",
                        },
                      },
                      [_vm._v(_vm._s(_vm.statistics.finishnum))]
                    ),
                  ]),
                ]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      "header-cell-class-name": "header-call-style",
                      border: "",
                      data: _vm.tableData,
                      "row-style": _vm.rowStyle,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        label: _vm.$t("list.index"),
                        width: "70",
                        align: "center",
                      },
                    }),
                    _vm._l(_vm.tableCols, function (item) {
                      return _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          prop: item.prop,
                          label: item.label,
                          width: item.width,
                          formatter: item.formatter,
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                      })
                    }),
                    _vm.$route.meta.authority.button.detail ||
                    _vm.$route.meta.authority.button.deal
                      ? _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: "center",
                            width: "90",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-dropdown",
                                      {
                                        on: {
                                          command: function ($event) {
                                            return _vm.handleCommand(
                                              $event,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { padding: "0" },
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                          },
                                          [
                                            _vm._v("操作"),
                                            _c("i", {
                                              staticClass: "el-icon-arrow-down",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "el-dropdown-menu",
                                          {
                                            attrs: { slot: "dropdown" },
                                            slot: "dropdown",
                                          },
                                          [
                                            _vm.$route.meta.authority.button
                                              .detail
                                              ? _c(
                                                  "el-dropdown-item",
                                                  { attrs: { command: "a" } },
                                                  [_vm._v("查询详情")]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      scope.row
                                                        .complaintStatus !==
                                                        "已办结" &&
                                                      _vm.$route.meta.authority
                                                        .button.deal,
                                                    expression:
                                                      "\n                      scope.row.complaintStatus !== '已办结' && $route.meta.authority.button.deal\n                    ",
                                                  },
                                                ],
                                                attrs: { command: "b" },
                                              },
                                              [_vm._v("处理")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3341848165
                          ),
                        })
                      : _vm._e(),
                  ],
                  2
                ),
                _c("div", { staticClass: "pagerWrapper" }, [
                  _c(
                    "div",
                    { staticClass: "block" },
                    [
                      _vm.total != 0
                        ? _c("el-pagination", {
                            attrs: {
                              "current-page": _vm.page,
                              "page-size": _vm.pageSize,
                              layout: "total, prev, pager, next, jumper",
                              total: _vm.total,
                            },
                            on: { "current-change": _vm.handleCurrentChange },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c("upload-file", {
              ref: "upload",
              attrs: {
                uploadData: _vm.uploadData,
                uploadUrl: _vm.uploadUrl,
                modelUrl: _vm.modelUrl,
              },
              on: { success: _vm.searchData },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }